import {
  Image,
  Linking,
  Platform,
  TouchableOpacity,
  View,
  Text,
} from 'react-native';
import React from 'react';
import {style} from './Header.styles';
import {Logo} from '../../assets/svg';
import Button from '../Button/Button';
import {FormattedMessage} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {NavigationNames} from '../../utils/routeNames';
import useDimensionsHook from '../../hooks/useDimensions';
import {Breakpoints} from '../../utils/theme';
import {apple, googlePlay, languageIcon} from '../../assets/png';
import {SupportURLs} from '../../services/api/path';
import {useModal} from '../../setup/providers/modalContext/ModalContext';
import i18next from '../../services/i18next';
import {useTranslation} from 'react-i18next';

const LandingPageHeader: React.FC = () => {
  const {openModal} = useModal();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {windowWidth} = useDimensionsHook();
  const isWeb = Platform.OS === 'web' && windowWidth > Breakpoints.md;
  const isScreenIsSmaller =
    Platform.OS === 'web' &&
    windowWidth < Breakpoints.xl &&
    windowWidth > Breakpoints.md;

  const openPlaystore = async () => {
    const supported = await Linking.canOpenURL(SupportURLs.PlayStoreApp);

    if (supported) {
      await Linking.openURL(SupportURLs.PlayStoreApp);
    } else {
      openModal({
        message: t('login.urlDoesNotSupport'),
      });
    }
  };

  const changeLang = (lng: any) => {
    i18next.changeLanguage(lng);
  };

  const openAppStore = async () => {
    const supported = await Linking.canOpenURL(SupportURLs.AppleStore);

    if (supported) {
      await Linking.openURL(SupportURLs.AppleStore);
    } else {
      openModal({
        message: t('login.urlDoesNotSupport'),
      });
    }
  };
  return (
    <View
      style={[
        style.landingContainer,
        {paddingRight: isScreenIsSmaller ? '16%' : 40},
      ]}>
      <View style={style.logoContainer}>
        <Logo height={120} width={120} style={style.logoStyle} />
        <View style={style.logoTextContainer}>
          <Text style={style.logoText}>
            श्री समस्त राजस्थान जैन समाज वडोदरा
          </Text>
        </View>
      </View>

      <View
        style={{
          flexDirection: isWeb ? 'row' : 'column',
          alignItems: isWeb ? 'center' : 'flex-end',
        }}>
        <View
          style={[
            style.landingRowView,
            //@ts-ignore
            {order: isWeb ? 1 : 2, gap: 0, marginRight: 10},
          ]}>
          <TouchableOpacity activeOpacity={0.8} onPress={openAppStore}>
            <Image
              style={{
                width: 120,
                height: 60,
                resizeMode: 'contain',
              }}
              source={apple}
            />
          </TouchableOpacity>
          <TouchableOpacity activeOpacity={0.8} onPress={openPlaystore}>
            <Image
              style={{
                width: 120,
                height: 60,
                resizeMode: 'contain',
              }}
              source={googlePlay}
            />
          </TouchableOpacity>
        </View>
        <View
          style={[
            style.landingRowView,
            //@ts-ignore
            {order: isWeb ? 2 : 1},
          ]}>
          <Button
            textStyle={style.loginText}
            style={style.loginButton}
            name={<FormattedMessage id={t('misc.login')} />}
            onPress={() => navigate(NavigationNames.Login)}
          />
          <Button
            textStyle={style.signUpButtonText}
            style={style.signUpButton}
            name={<FormattedMessage id={t('login.signUp')} />}
            onPress={() => navigate(NavigationNames.SignUp)}
          />
          <View style={style.langSelectionWrapper}>
            <Image
              style={{
                width: 60,
                height: 50,
                resizeMode: 'contain',
              }}
              source={languageIcon}
            />
            <Text
              style={style.languageName}
              onPress={() => {
                changeLang('en');
              }}>
              English |
            </Text>

            <Text
              style={style.languageName}
              onPress={() => {
                changeLang('hi');
              }}>
              हिंदी
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default LandingPageHeader;
