import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  ForgotPasswordController,
  ForgotPasswordFormFields,
} from './ForgotPassword.types';
import {forgotPasswordFormSchema} from '../../validationSchemas/forgotPassword';
import axios from '../../services/api/api';
import {ApiRoutes, SupportURLs} from '../../services/api/path';
import {useEffect} from 'react';
import {Linking, Platform} from 'react-native';
import {useTranslation} from 'react-i18next';
import {useModal} from '../../setup/providers/modalContext/ModalContext';

export const useLoginController = (): ForgotPasswordController => {
  const {openModal} = useModal();
  const {t} = useTranslation();
  const {
    control,
    handleSubmit,
    formState: {errors},
    setValue,
    reset,
    watch,
  } = useForm<ForgotPasswordFormFields>({
    resolver: yupResolver(forgotPasswordFormSchema),

    mode: 'onSubmit',
    defaultValues: {
      isLoading: false,
    },
  });

  const {isLoading, newUserModal, mobileNumber} = watch();

  useEffect(() => {
    if (mobileNumber) {
      setValue('mobileNumber', mobileNumber?.replace(/[^0-9]/g, ''));
    }
  }, [mobileNumber, setValue]);

  const onSubmit = async (
    data: ForgotPasswordFormFields,
    onSuccess: () => void,
  ) => {
    try {
      setValue('isLoading', true);
      const {data: response} = await axios.post<any>(ApiRoutes.ForgotPassword, {
        UserName: data.mobileNumber,
        RequestById: 9,
      });

      setValue('isLoading', false);
      reset();
      if (response.isSend === true) {
        reset();
        openModal({
          title: t('misc.passwordSentStatus'),
          message: response.message,
        });
        onSuccess();
        return;
      }
    } catch (e) {
      setValue('isLoading', false);
      openModal({
        title: t('misc.somethingWentWrongMessage'),
        message: t('misc.somethingWentWrongMessage'),
      });
    }
  };

  const openWhatsApp = async () => {
    const supported = await Linking.canOpenURL(
      Platform.OS === 'web' ? SupportURLs.WhatsAppWeb : SupportURLs.WhatsApp,
    );

    if (supported) {
      await Linking.openURL(
        Platform.OS === 'web' ? SupportURLs.WhatsAppWeb : SupportURLs.WhatsApp,
      );
    } else {
      openModal({
        message: t('login.urlDoesNotSupport'),
      });
    }
  };

  const openEmail = async () => {
    const supported = await Linking.canOpenURL(SupportURLs.Email);

    if (supported) {
      await Linking.openURL(SupportURLs.Email);
    } else {
      openModal({
        message: t('login.urlDoesNotSupport'),
      });
    }
  };

  return {
    control,
    onSubmit,
    handleSubmit,
    errors,
    isLoading,
    setValue,
    newUserModal,
    openWhatsApp,
    openEmail,
    watch,
  };
};
