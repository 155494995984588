import {useCallback, useState} from 'react';
import axios from '../../../services/api/api';
import {BoardMember} from '../../../services/api/response/community';
import {useAppSelector} from '../../../services/redux/hook';
import {ProfileDetailController} from './ProfileDetail.types';
import {User, UserDetailsResponse} from '../../../services/api/response/user';
import {useFocusEffect} from '@react-navigation/native';
import {ApiRoutes} from '../../../services/api/path';

export const useProfileDetail = (
  props: BoardMember,
): ProfileDetailController => {
  const {user} = useAppSelector(state => state.userReducer);
  const [profile, setProfile] = useState<User>();
  const [isLoading, setIsLoading] = useState(true);

  useFocusEffect(
    useCallback(() => {
      getProfileDetail();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  );

  const getProfileDetail = async () => {
    try {
      const {data} = await axios.post<UserDetailsResponse>(
        ApiRoutes.UserDetails,
        {
          UId: user?.UId,
          ApiSecret: user?.ApiSecret,
          LoginId: props.loginId,
        },
      );
      setProfile(data.userDetails[0]);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };
  return {
    profile,
    isLoading,
  };
};
