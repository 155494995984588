import {useCallback, useEffect} from 'react';
import {
  EditProfileController,
  EditProfileFormFields,
} from './EditProfile.types';
import {useAppDispatch, useAppSelector} from '../../../services/redux/hook';
import {useForm} from 'react-hook-form';
import {
  fetchAllOccupation,
  fetchAllPanths,
  fetchAreaByCity,
  fetchCitiesByDistruct,
  fetchDistrictsByState,
  fetchStateData,
  getContactAreasDataAction,
  getContactCitiesDataAction,
  getContactDistrictsDataAction,
  getOfficeAreasDataAction,
  getOfficeCitiesDataAction,
  getOfficeDistrictsDataAction,
} from '../../../services/redux/app/app.action';
import {AddressObject} from '../../../services/api/response/app';
import axios from '../../../services/api/api';
import {Platform} from 'react-native';
import {ApiRoutes} from '../../../services/api/path';
import {yupResolver} from '@hookform/resolvers/yup';
import {signUpFormSchema} from '../../../validationSchemas/signUp';
import {updateUserInfoAction} from '../../../services/redux/user/user.action';
import {User} from '../../../services/api/response/user';
import {useModal} from '../../../setup/providers/modalContext/ModalContext';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

export const useEditProfileController = (): EditProfileController => {
  const {t} = useTranslation();
  const {openModal} = useModal();
  const {
    states,
    contactDistrics,
    contactAreas,
    contactCities,
    officeAreas,
    officeCities,
    officeDistrics,
    panths,
    occupations,
  } = useAppSelector(state => state.appReducer);
  const {user} = useAppSelector(state => state.userReducer);
  const dispatch = useAppDispatch();
  const {
    control,
    watch,
    handleSubmit,
    formState: {errors},
    setValue,
    trigger,
    reset,
  } = useForm<EditProfileFormFields>({
    resolver: yupResolver(signUpFormSchema),
    mode: !user?.hasProfileCompleted ? 'onChange' : 'onSubmit',
  });
  const {
    contactState,
    contactDistrict,
    contactCity,
    officeState,
    officeCity,
    officeDistrict,
    isLoading,
    panth,
    occupation,
    bloodGroup = '',
  } = watch();

  useEffect(() => {
    if (bloodGroup) {
      setValue('bloodGroup', bloodGroup?.toUpperCase());
    }
  }, [bloodGroup, setValue]);

  useEffect(() => {
    reset({
      isLoading: false,
      firstName: user?.firstName || '',
      middleName: user?.middleName || '',
      lastName: user?.lastName || '',
      fatherName: user?.fatherName || '',
      email: user?.email,
      bloodGroup: user?.bloodGroup || '',
      dob: user?.dob ? new Date(user.dob) : undefined,
      mobileNumber: user?.mobileNo || '',
      whatsAppNumber: user?.whatsAppNo || '',
      occupation:
        occupations?.find(x => x.occupationName === user?.occupation) ??
        occupations?.[occupations?.length],
      occupationName: user?.occupation,
      gotra: user?.gotra || '',
      panth:
        panths?.find(x => x.panthName === user?.panthName) ??
        panths?.[panths?.length],
      panthName: user?.panthName,
      contactAddressLine1: user?.homeAddressLine1 || '',
      contactAddressLine2: user?.homeAddressLine2 || '',
      contactLandmark: user?.homeAddressLandMark || '',
      contactState: user?.homeState?.[0],
      contactDistrict: user?.homeDistrict?.[0],
      contactCity: user?.homeCity?.[0],
      contactArea: user?.homeArea?.[0],
      businessName: user?.businessName,
      officeAddressLine1: user?.officeAddressLine1 || '',
      officeAddressLine2: user?.officeAddressLine2 || '',
      officeLandmark: user?.officeAddressLandMark || '',
      officeState: user?.officeState?.[0],
      officeDistrict: user?.officeDistrict?.[0],
      officeCity: user?.officeCity?.[0],
      officeArea: user?.officeArea?.[0],
      profileImage: user?.profileImage || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (occupations.length) {
      setValue(
        'occupation',
        occupations?.find(x => x.occupationName === user?.occupation) ??
          occupations?.[occupations?.length],
      );
    }
    if (panths.length) {
      setValue(
        'panth',
        panths?.find(x => x.panthName === user?.panthName) ??
          panths?.[panths?.length],
      );
    }
    if (!user?.hasProfileCompleted) {
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setValue,
    occupations.length,
    panths.length,
    user?.occupation,
    user?.panthName,
    trigger,
    user?.hasProfileCompleted,
  ]);

  useEffect(() => {
    //@ts-ignore
    dispatch(fetchStateData());

    //@ts-ignore
    dispatch(fetchAllPanths());
    //@ts-ignore
    dispatch(fetchAllOccupation());
  }, [dispatch]);

  const getContactDistrics = useCallback(async () => {
    if (contactState) {
      const districts = (await fetchDistrictsByState(
        contactState.id,
      )) as AddressObject[];
      dispatch(getContactDistrictsDataAction(districts));
    } else {
      dispatch(getContactDistrictsDataAction([]));
    }
  }, [contactState, dispatch]);

  const getContactCities = useCallback(async () => {
    if (contactDistrict) {
      const cities = (await fetchCitiesByDistruct(
        contactDistrict.id,
      )) as AddressObject[];
      dispatch(getContactCitiesDataAction(cities));
    } else {
      dispatch(getContactCitiesDataAction([]));
    }
  }, [contactDistrict, dispatch]);

  const getContactAreas = useCallback(async () => {
    if (contactCity) {
      const areas = (await fetchAreaByCity(contactCity.id)) as AddressObject[];
      dispatch(getContactAreasDataAction(areas));
    } else {
      dispatch(getContactAreasDataAction([]));
    }
  }, [contactCity, dispatch]);

  const getOfficeDistrics = useCallback(async () => {
    if (officeState) {
      const districts = (await fetchDistrictsByState(
        officeState.id,
      )) as AddressObject[];
      dispatch(getOfficeDistrictsDataAction(districts));
    } else {
      dispatch(getOfficeDistrictsDataAction([]));
    }
  }, [officeState, dispatch]);

  const getOfficeCities = useCallback(async () => {
    if (officeDistrict) {
      const cities = (await fetchCitiesByDistruct(
        officeDistrict.id,
      )) as AddressObject[];
      dispatch(getOfficeCitiesDataAction(cities));
    } else {
      dispatch(getOfficeCitiesDataAction([]));
    }
  }, [officeDistrict, dispatch]);

  const getOfficeAreas = useCallback(async () => {
    if (officeCity) {
      const areas = (await fetchAreaByCity(officeCity.id)) as AddressObject[];
      dispatch(getOfficeAreasDataAction(areas));
    } else {
      dispatch(getOfficeAreasDataAction([]));
    }
  }, [officeCity, dispatch]);

  useEffect(() => {
    getContactDistrics();
  }, [getContactDistrics]);

  useEffect(() => {
    getContactCities();
  }, [getContactCities]);

  useEffect(() => {
    getContactAreas();
  }, [getContactAreas]);

  useEffect(() => {
    getOfficeDistrics();
  }, [getOfficeDistrics]);

  useEffect(() => {
    getOfficeCities();
  }, [getOfficeCities]);

  useEffect(() => {
    getOfficeAreas();
  }, [getOfficeAreas]);

  const onSubmit = async (
    data: EditProfileFormFields,
    onSuccess: () => void,
  ) => {
    try {
      setValue('isLoading', true);
      const {data: response} = await axios.post(ApiRoutes.UpdateUserDetails, {
        UId: user?.UId,
        ApiSecret: user?.ApiSecret,
        ProfilePicture: data.profileImage?.includes('.jpg')
          ? null
          : data.profileImage,
        FirstName: data.firstName,
        MiddleName: data.middleName,
        FatherName: data.fatherName,
        LastName: data.lastName,
        Email: data.email,
        BloodGroup: data.bloodGroup,
        DOB: moment(data.dob).format('DD MM YYYY'),
        MobileNo: data.mobileNumber,
        WhatsAppNo: data.whatsAppNumber,
        OccupationId:
          data.occupation.occupationName === 'Other'
            ? null
            : data.occupation.id,
        Occupation:
          data.occupation.occupationName === 'Other'
            ? data.occupationName
            : data.occupation.occupationName,
        Gotra: data.gotra,
        HomeAddressLine1: data.contactAddressLine1,
        HomeAddressLine2: data.contactAddressLine2,
        HomeAddressLandMark: data.contactLandmark,
        HomeAreaId: data.contactArea?.id,
        BusinessName: data?.businessName,
        OfficeAddressLine1: data.officeAddressLine1,
        OfficeAddressLine2: data.officeAddressLine2,
        OfficeAddressLandMark: data.officeLandmark,
        OfficeAreaId: data.officeArea?.id || null,
        PanthName:
          data.panth?.panthName === 'Other'
            ? data.panthName
            : data.panth?.panthName,
        PanthId: data.panth?.panthName === 'Other' ? '' : data.panth?.id,
        RequestById: Platform.OS === 'web' ? 5 : 7,
        IsAdmin: 0,
      });
      if (response?.isSaved) {
        fetchUserDetails(onSuccess);
      } else {
        setValue('isLoading', false);
        openModal({
          title: t('profile.editProfileFailed'),
          message: response?.message,
        });
      }
    } catch (e) {
      setValue('isLoading', false);
      console.error(e);
    }
  };

  const fetchUserDetails = async (onSuccess: () => void) => {
    try {
      const {data} = await axios.post(ApiRoutes.UserDetails, {
        UId: user?.UId,
        ApiSecret: user?.ApiSecret,
        LoginId: user?.uId,
      });
      setValue('isLoading', false);
      if (data?.isAuthorisedUser) {
        const response: User = data.userDetails[0];
        dispatch(updateUserInfoAction(response));
        onSuccess();
        openModal({
          title: t('misc.success'),
          message: t('profile.editProfileSuccess'),
        });

        return;
      } else {
        openModal({
          title: t('profile.editProfileFailed'),
          message: data?.message,
        });
        return;
      }
    } catch (e) {
      setValue('isLoading', false);

      console.error(e);
    }
  };

  return {
    states,
    control,
    contactDistrictData: contactDistrics,
    contactCitiesData: contactCities,
    contactAreasData: contactAreas,
    officeDistrictData: officeDistrics,
    officeCitiesData: officeCities,
    officeAreasData: officeAreas,
    occupations,
    occupation,
    panths,
    onSubmit,
    handleSubmit,
    errors,
    isLoading,
    panth,
    contactState,
    contactDistrict,
    contactCity,
    officeState,
    officeDistrict,
    officeCity,
    setValue,
  };
};
