import {StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';

export const style = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    flex: 1,
    display: 'flex',
  },
  keyBoardView: {
    flex: 1,
  },
  scrollView: {
    flexGrow: 1,
    paddingVertical: 30,
    paddingTop: 0,
  },
  headerRowView: {
    flexDirection: 'row',
    alignItems: 'center',
    shadowColor: Colors.lightBlack,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
    backgroundColor: Colors.white,
    justifyContent: 'center',
    paddingVertical: 20,
  },
  logoStyle: {
    alignSelf: 'center',
    position: 'relative',
    flexDirection: 'column',
    // left: 30,
  },
  logoContainer: {
    alignItems: 'center',
  },
  logoText: {
    fontFamily: FontFamily.PoppinsItalic,
    fontSize: 20,
    // color: '#1e3465',
    color: '#17179b',
    fontWeight: '600',
  },
  signupLogoText: {
    fontFamily: FontFamily.PoppinsItalic,
    fontSize: 16,
    // color: '#1e3465',
    color: '#17179b',
    alignSelf: 'center',
    fontWeight: '600',
    position: 'relative',
  },
  logoTextContainer: {
    width: '40%',
    height: 80,
    fontFamily: FontFamily.PoppinsBold,
    fontSize: 25,
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'relative',
    marginLeft: 130,
    right: 100,
    flexDirection: 'column',
  },
  logoText_M_Container: {
    // width: '60%',
    height: 50,
    fontFamily: FontFamily.PoppinsBold,
    fontSize: 25,
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'relative',
    // left: 35,
  },
  headerTitle: {
    fontSize: FontSize.h2,
    fontFamily: FontFamily.PoppinsSemiBold,
    textAlign: 'center',
    marginBottom: 5,
    marginTop: 15,
    color: Colors.blackA100,
  },
  description: {
    fontSize: FontSize.h6,
    fontFamily: FontFamily.PoppinsRegular,
    color: Colors.lightBlack,
    marginBottom: 50,
  },
  contentContainer: {
    flex: 1,
    padding: 15,
    paddingHorizontal: 20,
  },
  backButton: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
    position: 'absolute',
    left: 0,
  },
  backText: {
    fontSize: FontSize.h6,
    fontFamily: FontFamily.PoppinsSemiBold,
    color: Colors.primary,
  },
  nameRowView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 5,
  },
  inputContainer: {
    marginBottom: 10,
    flex: 1,
  },
  title: {
    fontSize: FontSize.h6,
    fontFamily: FontFamily.PoppinsRegular,
    color: Colors.blackA100,
    marginBottom: 15,
    marginTop: 30,
  },
  submitButton: {
    marginTop: 40,
  },
  byCreatingAccount: {
    flex: 1,
    fontSize: FontSize.body,
    fontFamily: FontFamily.PoppinsRegular,
    color: Colors.lightBlack,
  },
  privacyPolicy: {
    fontSize: FontSize.bodyBig,
    fontFamily: FontFamily.PoppinsMedium,
    color: Colors.primary,
    position: 'relative',
    top: 2,
    textDecorationLine: 'underline',
  },

  privacyContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    paddingVertical: 10,
  },
  privacyText: {
    fontSize: FontSize.body,
    fontFamily: FontFamily.PoppinsRegular,
    color: '#000',
  },
  checkBox: {
    marginRight: 10,
  },
  dobContainer: {
    marginVertical: 10,
    flex: 1,
  },
  dobLabel: {
    fontSize: 16,
    marginRight: 10,
  },
  dobIcon: {
    marginLeft: 5,
    width: 50,
    height: 50,
  },
  dobInput: {
    flex: 1,
    borderBottomWidth: 1,
    borderColor: '#ccc',
    paddingVertical: 8,
    paddingHorizontal: 5,
    marginLeft: 10,
  },
  dobText: {
    fontSize: 16,
    color: '#000',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  dateContainer: {
    width: '100%',
    flex: 1,
  },
  datePickerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  datePickerButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  datePickerText: {
    color: '#000',
    padding: 10,
    fontSize: 16,
  },
});
