import {
  DimensionValue,
  FlatList,
  ImageBackground,
  Linking,
  Platform,
  Text,
  View,
} from 'react-native';
import React, {useMemo} from 'react';
import {LandingPageHeader, MemberInfo} from '../../components';
import {styles} from './LandingPage.styles';
import Swiper from 'react-native-web-swiper';
import {FormattedMessage} from 'react-intl';
import {SupportURLs} from '../../services/api/path';
import {useModal} from '../../setup/providers/modalContext/ModalContext';
import {Logo} from '../../assets/svg';
import {boardMembers} from '../../utils/json';
import useDimensionsHook from '../../hooks/useDimensions';
import {Breakpoints, Colors} from '../../utils/theme';
import {landingPageData} from './LandingPage.const';
import {useNavigate} from 'react-router-dom';
import {NavigationNames} from '../../utils/routeNames';
import {Trans, useTranslation} from 'react-i18next';

const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  const {openModal} = useModal();
  const {windowWidth, windowHeight} = useDimensionsHook();
  const isWeb = Platform.OS === 'web' && windowWidth > Breakpoints.md;
  const {t} = useTranslation();
  const sliderHeight: DimensionValue = isWeb
    ? windowHeight / 1.7
    : windowHeight / 3 < 300
    ? 250
    : windowHeight / 3;

  const boardMembersData = useMemo(() => {
    if (isWeb) {
      const dividedArrays = [];
      for (let i = 0; i < boardMembers.length; i += 6) {
        const chunk = boardMembers.slice(i, i + 6);
        dividedArrays.push(chunk);
      }
      return dividedArrays;
    }

    return boardMembers;
  }, [isWeb]);

  const openReadMore = async (url: string) => {
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      await Linking.openURL(url);
    } else {
      openModal({
        message: t('login.urlDoesNotSupport'),
      });
    }
  };

  const H4 = (props: any) => (
    <Text
      style={styles.semiTitle}
      onPress={() => openReadMore(SupportURLs.AboutUs)}>
      {props.children}
    </Text>
  );
  return (
    <View style={styles.container}>
      <View style={styles.scrollView}>
        <View style={[styles.bannerView, {minHeight: sliderHeight - 30}]}>
          <Swiper
            key={isWeb?.toString()}
            loop
            timeout={10}
            springConfig={{speed: 11}}
            controlsProps={{
              dotsTouchable: true,
              prevPos: false,
              nextPos: false,
              dotActiveStyle: styles.dotActiveStyle,
              dotProps: {
                badgeStyle: styles.dotInActiveStyle,
              },
            }}
            minDistanceForAction={0}>
            {landingPageData.map(item => (
              <ImageBackground
                key={item.id}
                style={styles.imageBackground}
                imageStyle={{
                  width: '100%',
                  height: '100%',
                  resizeMode: 'stretch',
                }}
                source={item.image}>
                <View
                  style={[
                    styles.imageStripView,
                    {marginBottom: isWeb ? 100 : 50},
                  ]}>
                  <Text style={styles.imageText}>{item?.name}</Text>
                </View>
              </ImageBackground>
            ))}
          </Swiper>
        </View>
        <View>
          <Text style={styles.aboutUs}>
            <FormattedMessage id={t('home.aboutUs')} />
          </Text>
          <Text style={styles.description}>
            <Trans i18nKey="home.description" components={{h4: <H4 />}} />
          </Text>
        </View>
        <Text style={styles.boardMemberText}>
          <FormattedMessage id={t('misc.boardMembers')} />
        </Text>
        <Swiper
          loop
          timeout={6}
          key={isWeb?.toString()}
          springConfig={{speed: 11}}
          controlsProps={{
            dotsTouchable: true,
            prevPos: false,
            nextPos: false,
            dotActiveStyle: styles.dotActiveStyle,
            dotProps: {
              badgeStyle: {
                backgroundColor: Colors.lightGray,
              },
            },
          }}
          containerStyle={{minHeight: isWeb ? 450 : 300}}
          minDistanceForAction={0}>
          {isWeb
            ? boardMembersData.map(item => (
                <View style={{flex: 1}}>
                  <FlatList
                    //@ts-ignore
                    data={item}
                    numColumns={3}
                    columnWrapperStyle={{gap: 20}}
                    contentContainerStyle={{gap: 20, padding: 10}}
                    renderItem={({item: member}) => (
                      <MemberInfo
                        loginId={member.id}
                        position={''}
                        image={member.images}
                        disabled={true}
                        name={member.name?.toLowerCase()}
                        containerStyle={{maxWidth: 'auto', minHeight: '100%'}}
                      />
                    )}
                  />
                </View>
              ))
            : boardMembers.map(item => (
                <MemberInfo
                  loginId={item.id}
                  position={item.number}
                  name={item.name?.toLowerCase()}
                  image={0}
                  disabled={true}
                  containerStyle={{maxWidth: 'auto', maxHeight: 250}}
                />
              ))}
        </Swiper>
        <View style={styles.footer}>
          {isWeb && (
            <View style={{gap: 20}}>
              <View style={styles.logoContainer}>
                <Logo height={120} width={120} style={styles.logo} />
                <View style={styles.logoTextContainer}>
                  <Text style={styles.logoText}>
                    {' '}
                    श्री समस्त राजस्थान जैन समाज वडोदरा
                  </Text>
                </View>
              </View>
              <Text style={styles.copyRights}>
                <FormattedMessage id="misc.copyRights" />
              </Text>
            </View>
          )}
          <View style={styles.footerItemView}>
            <Text
              style={styles.footerLinks}
              onPress={() => navigate(NavigationNames.ContactUs)}>
              <FormattedMessage id={t('contactUs.contactUs')} />
            </Text>
            <Text
              style={styles.footerLinks}
              onPress={() => openReadMore(SupportURLs.AboutUs)}>
              <FormattedMessage id={t('misc.aboutUs')} />
            </Text>
            <Text
              style={styles.footerLinks}
              onPress={() => openReadMore(SupportURLs.TermsAndService)}>
              <FormattedMessage id={t('misc.termsAndConditions')} />
            </Text>
            <Text
              style={styles.footerLinks}
              onPress={() => openReadMore(SupportURLs.PrivacyPolicy)}>
              <FormattedMessage id={t('misc.privacyPolicy')} />
            </Text>
          </View>
        </View>
      </View>
      <LandingPageHeader />
    </View>
  );
};

export default LandingPage;
