export const reqLowerRegex = /(?=.*[a-z])/;
export const reqUpperRegex = /(?=.*[A-Z])/;
export const reqNumbersRegex = /(?=.*[0-9])/;
// eslint-disable-next-line no-useless-escape
export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
export const specialCharsRegex =
  // eslint-disable-next-line no-useless-escape
  /[~`!@#$%^&*()\-_+={}\[\]|\\;:"'<>,.\/?]+/;

export const passwordRegex =
  // eslint-disable-next-line no-useless-escape
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()\-_+={}\[\]|\\;:"'<>,.\/?])[a-zA-Z \d~`!@#$%^&*()\-_+={}\[\]|\\;:"'<>,.\/?]{6,}$/;

export const nameRegex =
  // eslint-disable-next-line no-useless-escape
  /^[a-zA-Z.\u00C0-\u00FF\s]{1,}((\'|\`|\-|\s)?[a-zA-Z‘’'\u00C0-\u00FF\s\u2018\u2019\u201C\u201D]){0,}$/;

export const addressRegex =
  // eslint-disable-next-line no-useless-escape
  /^[a-zA-Z\u00C0-\u00FF\s]{1,}((\'|\`|\-|\s|,)?[a-zA-Z\u00C0-\u00FF\s]){0,}$/;

export const middleNameRegex =
  // eslint-disable-next-line no-useless-escape
  /^[a-zA-Z.\u00C0-\u00FF]{0,}((\'|\`|\-|\s)?[a-zA-Z‘’'\u00C0-\u00FF\s]){0,}$/;

export const taxIdentificationNumberRegex =
  // eslint-disable-next-line no-useless-escape
  /^[a-zA-Z\d]{1,}((\-|\s|\+|\-|\.|\/)?[a-zA-Z\d]){0,}$/;

export const PEPStringRegex = /^[a-zA-Z ',.\u00C0-\u00FF]*$/;

export const phoneRegex = /^[6-9]\d{9}$/;

export const alphabetSpaceRegex = /^[a-zA-Z ]*$/;

export const alphabetNumberRegex = /^[A-Z0-9]*$/;

export const alphabetWithSeperatorType =
  // eslint-disable-next-line no-useless-escape
  /^[a-zA-Z `\-_\|;:"',.\/?\u00C0-\u00FF]*$/;

export const bloodGroupRegex = /^(A|B|AB|O|ABO)[+-]?$/;
