import {
  View,
  FlatList,
  RefreshControl,
  TextInput,
  TouchableOpacity,
  Modal,
} from 'react-native';
import React, {useState} from 'react';
import {style} from './AllMembers.styles';
import {useDimensions} from '../../../hooks/useDimensions';
import {Breakpoints} from '../../../utils/theme';
import {EmptyComponent, MemberInfo, Spinner} from '../../../components';
import {useAllMembersController} from './AllMembers.controller';
import {ApiRoutes} from '../../../services/api/path';
import {CommunityMembers, Filter} from '../../../assets/svg';
import {FormattedMessage} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {NavigationNames} from '../../../utils/routeNames';
import {BoardMember} from '../../../services/api/response/community';

const AllMembers: React.FC = () => {
  const {windowWidth} = useDimensions();
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [filterValue, setFilterValue] = useState<string | null>(null);

  const isMobile =
    windowWidth <= Breakpoints.md && windowWidth >= Breakpoints.xs;
  const columnCount = isMobile ? 2 : Math.floor(windowWidth / 300);
  const {allMembers, loading, fetchAllMembers} = useAllMembersController();

  const formatDateString = (date: string | Date | null | undefined): string => {
    if (!date) return '';

    const dateObj = typeof date === 'string' ? new Date(date) : date;
    if (!(dateObj instanceof Date) || isNaN(dateObj.getTime())) return '';

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const filteredMembers = allMembers
    .filter(member => {
      const formattedDOB = member.dob ? formatDateString(member.dob) : '';
      const lowerCaseQuery = filterValue?.toLowerCase();

      switch (selectedFilter) {
        case 'name':
          //@ts-ignore
          return member.name.toLowerCase().includes(lowerCaseQuery);
        case 'dob':
          //@ts-ignore
          return formattedDOB.includes(lowerCaseQuery);
        case 'bloodGroup':
          //@ts-ignore
          return member.bloodGroup?.toLowerCase().includes(lowerCaseQuery);
        case 'occupation':
          //@ts-ignore
          return member.position.toLowerCase().includes(lowerCaseQuery);
        default:
          return true;
      }
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const onProfilePress = (item: BoardMember): void => {
    navigate(NavigationNames.ProfileDetail, {
      state: {
        profile: item,
      },
    });
  };

  const handleFilterSelection = (filter: string) => {
    setSelectedFilter(filter);
    setFilterValue('');
    setModalVisible(false);
  };

  return (
    <View style={style.container}>
      <Spinner visible={loading.fetching} />

      <TouchableOpacity
        style={style.floatingButton}
        onPress={() => setModalVisible(true)}>
        <Filter style={style.filterIcon} />
      </TouchableOpacity>

      <Modal
        transparent={true}
        animationType="slide"
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}>
        <View style={style.modalContainer}>
          {['name', 'dob', 'bloodGroup', 'occupation'].map(filter => (
            <TouchableOpacity
              key={filter}
              onPress={() => handleFilterSelection(filter)}
              style={style.optionButton}>
              <text>{filter.charAt(0).toUpperCase() + filter.slice(1)}</text>
            </TouchableOpacity>
          ))}
          <TouchableOpacity
            onPress={() => setModalVisible(false)}
            style={style.closeButton}>
            <text>Close</text>
          </TouchableOpacity>
        </View>
      </Modal>

      {selectedFilter && (
        <TextInput
          style={style.input}
          placeholder={`Search by ${
            selectedFilter.charAt(0).toUpperCase() + selectedFilter.slice(1)
          }`}
          //@ts-ignore
          value={filterValue}
          onChangeText={text => setFilterValue(text)}
        />
      )}

      <FlatList
        key={columnCount}
        data={filteredMembers}
        numColumns={columnCount}
        keyExtractor={item => item.loginId.toString()}
        renderItem={({item}) => (
          <MemberInfo
            {...item}
            image={
              item?.image
                ? {
                    uri: `${ApiRoutes.BaseURL}${item?.image?.substring(1)}`,
                  }
                : 0
            }
            onPress={() => onProfilePress(item)}
          />
        )}
        columnWrapperStyle={style.columnWrapperStyle}
        contentContainerStyle={style.contentContainerStyle}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            refreshing={loading.refreshing}
            onRefresh={() => fetchAllMembers(true)}
          />
        }
        // eslint-disable-next-line react/no-unstable-nested-components
        ListEmptyComponent={() => (
          <EmptyComponent
            icon={<CommunityMembers width={250} height={250} />}
            isFetching={loading.fetching}
            message={<FormattedMessage id="community.noMembers" />}
          />
        )}
      />
    </View>
  );
};

export default AllMembers;
