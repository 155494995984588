export enum ApiRoutes {
  BaseURL = 'https://admin.ssrjs.org/',
  // BaseURL = 'http://61.246.39.128:987/',
  // BaseURL = 'http://192.168.0.33:810/',
  State = 'api/Address/States',
  AuthorisedUser = '/api/Login/AuthorisedUser',
  Districts = 'api/Address/DistrictsByStateId',
  Cities = 'api/Address/CitiesByDistrictId',
  Area = 'api/Address/AreasByCityId',
  Banners = 'api/User/HomeBannerRandomImages',
  CheckUserExist = 'api/Login/CheckUserExist',
  VerifyOTP = 'api/OTP/VerifyOTP',
  PanthNames = 'api/Employee/AllPanthNames',
  SignUp = 'api/Employee/RegisterUser',
  ResendOTP = 'api/OTP/ResendOTP',
  UserDetails = 'api/Employee/UserDetails',
  UpdateUserDetails = 'api/Employee/UpdateUserDetails',
  BoardMembers = 'api/Employee/AllBoardMembersDetails',
  AddFamilymember = 'api/Employee/AddFamilyMember',
  FamilyMembers = 'api/Employee/AllFamilyMembers',
  MatrimonialProfiles = 'api/Employee/AllMatrimonialProfiles',
  AllMembers = 'api/Employee/AllMembers',
  AddMatrimonialProfile = 'api/Employee/SaveMatrimonialProfile',
  Events = 'api/Employee/AllEvents',
  AllOccupation = 'api/Employee/AllOccupationNames',
  DeleteAccount = 'api/Employee/UserDelete',
  AllCategories = 'api/Utility/InquiryMessageCategories',
  InquirySubmit = 'api/Utility/SaveInquiry',
  ChangePassword = '/api/Login/ChangePassword',
  ForgotPassword = '/api/User/SendForgotPassword',
}

export enum SupportURLs {
  PrivacyPolicy = 'https://admin.ssrjs.org/PrivacyPolicy.html',
  AboutUs = 'https://admin.ssrjs.org/SSRJS-AboutUs.pdf',
  WhatsApp = 'whatsapp://send?&phone=917600136222',
  WhatsAppWeb = 'https://api.whatsapp.com/send?phone=917600136222',
  Email = 'mailto:ssrjsvadodara@gmail.com',
  TermsAndService = 'https://admin.ssrjs.org/TermsAndServices.html',
  PlayStoreApp = 'https://play.google.com/store/apps/details?id=com.samasthrajasthan',
  AppleStore = 'https://apps.apple.com/us/app/samast-rajasthan-jain-samaj/id6459793144',
  WhatsAppAndroid = 'WhatsAppAndroid',
}
