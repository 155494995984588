import React from 'react';
import {TouchableOpacity, Text, Linking} from 'react-native';
import {style} from './PdfViewer.styles';
import {PdfViewerProps} from './PdfViewer.types';
import {FormattedMessage} from 'react-intl';
import {ApiRoutes} from '../../services/api/path';

const PdfViewer: React.FC<PdfViewerProps> = ({pdfUrl}) => {
  const openPdfInBrowser = async () => {
    const pdfLink = `${ApiRoutes.BaseURL}${pdfUrl.substring(1)}`;
    const supported = await Linking.canOpenURL(pdfLink);

    if (supported) {
      await Linking.openURL(pdfLink);
    }
  };

  return (
    <TouchableOpacity onPress={openPdfInBrowser} style={style.webContainer}>
      <Text style={style.webText}>
        <FormattedMessage id="misc.viewDocument" />
      </Text>
    </TouchableOpacity>
  );
};

export default PdfViewer;
