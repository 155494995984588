import {StyleSheet, Platform} from 'react-native';
import {Colors} from '../../../utils/theme';

export const style = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    flex: 1,
    display: 'flex',
  },
  contentContainerStyle: {
    flexGrow: 1,
    paddingBottom: 30,
    paddingHorizontal: 16,
  },
  columnWrapperStyle: {
    paddingVertical: 10,
    gap: 15,
  },
  input: {
    marginTop: Platform.OS === 'web' ? 0 : 20,
    marginBottom: 20,
    alignSelf: 'center',
    width: Platform.OS === 'web' ? '90%' : '90%',
    borderWidth: 1,
    borderColor: Colors.maroon,
    borderRadius: 5,
    padding: 10,
  },

  floatingButton: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    width: 66,
    height: 66,
    borderRadius: 48,
    backgroundColor: Colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.3,
    shadowRadius: 4,
    zIndex: 1,
  },
  filterIcon: {
    width: 'auto',
  },
  floatingButtonText: {
    fontSize: 24,
    color: '#fff',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  optionButton: {
    backgroundColor: '#fff',
    padding: 16,
    marginVertical: 8,
    borderRadius: 8,
    width: '80%',
    alignItems: 'center',
    elevation: 2,
  },
  closeButton: {
    backgroundColor: Colors.primary,
    padding: 12,
    borderRadius: 8,
    fontWeight: 500,
    marginTop: 16,
    alignItems: 'center',
    width: '80%',
  },
  inputContainer: {
    marginVertical: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 8,
    backgroundColor: '#fff',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 2,
  },
});
