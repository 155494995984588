import {StyleSheet} from 'react-native';
import {Colors} from '../../../utils/theme';

export const style = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    flexDirection: 'column',
    flex: 1,
    display: 'flex',
  },
  contentContainerStyle: {
    flexGrow: 1,
    paddingBottom: 30,
    paddingHorizontal: 16,
  },
  columnWrapperStyle: {
    paddingVertical: 10,
    gap: 15,
  },
  memberWrapper: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    // borderWidth: 2,
    // borderColor: 'black',
    // borderStyle: 'solid',
  },
  positionTitle: {
    color: Colors.maroon,
    fontSize: 24,
    margin: 10,
  },
});
