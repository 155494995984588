import {View, KeyboardAvoidingView, Platform, ScrollView} from 'react-native';
import React from 'react';
import {
  Avatar,
  Button,
  CustomStatus,
  DatePicker,
  DropDown,
  Header,
  Input,
  InputWithControl,
  Spinner,
} from '../../../components';
import {style} from './AddFamilyMember.styles';
import {useAddFamilyMemberController} from './AddFamilyMember.controller';
import {getErrorProps} from '../../../utils/validation/validationFunctions';
import {FormattedMessage, useIntl} from 'react-intl';
import {Control, Controller, FieldValues} from 'react-hook-form';
import {BackArrow} from '../../../assets/svg';
import {familyRelation, gender, maritalStatus} from '../../../utils/json';
import {useLocation, useNavigate} from 'react-router-dom';
import {NavigationNames} from '../../../utils/routeNames';
import useDimensionsHook from '../../../hooks/useDimensions';
import {Breakpoints} from '../../../utils/theme';
import {ApiRoutes} from '../../../services/api/path';
import {useTranslation} from 'react-i18next';
import {bloodGroups} from '../../SignUp/BloodGroups';

const AddFamilyMember: React.FC = () => {
  const intl = useIntl();
  const {windowWidth} = useDimensionsHook();
  const {t} = useTranslation();
  const {state} = useLocation();
  const isWeb = Platform.OS === 'web' && windowWidth > Breakpoints.md;
  const {
    control,
    errors,
    setValue,
    handleSubmit,
    onSubmit,
    isLoading,
    occupation,
    occupations,
  } = useAddFamilyMemberController({member: state?.member});
  const navigate = useNavigate();

  const onSuccess = (): void => {
    navigate(NavigationNames.FamilyMembers, {
      replace: true,
    });
  };
  return (
    <View style={[style.container, {alignItems: isWeb ? 'center' : 'stretch'}]}>
      <CustomStatus />
      <Spinner visible={isLoading} />
      <Header
        leftComponent={<BackArrow width={20} height={20} />}
        leftComponentPress={onSuccess}
        title={<FormattedMessage id={t('familyMember.addFamilyMember')} />}
        rightComponent={null}
      />
      <KeyboardAvoidingView
        style={style.keyBoardView}
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={style.scrollView}>
          <View
            style={[
              style.contentContainer,
              {
                minWidth: isWeb ? Breakpoints.md : 'auto',
                maxWidth: Breakpoints.md,
              },
            ]}>
            <Avatar
              image={
                state?.member?.image
                  ? {
                      uri: `${
                        ApiRoutes.BaseURL
                      }${state?.member?.image?.substring(1)}`,
                    }
                  : 0
              }
              size="large"
              editable
              containerStyle={style.avatarStyle}
              onIconPress={image => setValue('profileImage', image)}
            />
            <InputWithControl
              control={control as unknown as Control<FieldValues>}
              name="name"
              label={t('familyMember.name')}
              containerStyle={style.inputContainer}
              {...getErrorProps(intl, errors.name)}
              onSubmitEditing={handleSubmit(data =>
                onSubmit({data, onSuccess}),
              )}
            />
            <View style={style.nameRowView}>
              <Controller
                control={control}
                name="relationShip"
                render={({field: {onChange, value}}) => (
                  <DropDown
                    data={familyRelation}
                    displayKey="name"
                    label={t('familyMember.relationWithYou')}
                    containerStyle={style.dropDownContainer}
                    value={value?.name}
                    onSelectItem={onChange}
                    {...getErrorProps(intl, errors.relationShip?.name)}
                  />
                )}
              />
            </View>
            <Controller
              control={control}
              name="dob"
              render={({field: {onChange, value}}) => (
                <DatePicker
                  label={t('familyMember.dateOfBirth')}
                  containerStyle={style.inputContainer}
                  {...getErrorProps(intl, errors.dob)}
                  onChange={onChange}
                  placeHolder="DD-MM-YYYY"
                  value={value}
                  mode="date"
                  maximumDate={new Date()}
                />
              )}
            />

            <Controller
              control={control as unknown as Control<FieldValues>}
              name="occupation"
              render={({field: {onChange, value}}) => (
                <DropDown
                  data={occupations}
                  displayKey="occupationName"
                  label={intl.formatMessage({id: t('profile.occupation')})}
                  containerStyle={style.inputContainer}
                  value={value?.occupationName}
                  onSelectItem={onChange}
                  {...getErrorProps(intl, errors.occupation)}
                />
              )}
            />
            {occupation && occupation.occupationName === 'Other' && (
              <Controller
                control={control}
                name="occupationName"
                render={() => (
                  <Input
                    //@ts-ignore
                    control={control as unknown as Control<FieldValues>}
                    name="occupationName"
                    placeholder={intl.formatMessage({
                      id: 'profile.occupationName',
                    })}
                    containerStyle={style.inputContainer}
                  />
                )}
              />
            )}
            {/* <Controller
              control={control as unknown as Control<FieldValues>}
              name="occupation"
              render={({field: {onChange, value}}) => (
                <DropDown
                  data={occupations}
                  displayKey="occupationName"
                  label={t('profile.familyMemberOccupation')}
                  containerStyle={style.inputContainer}
                  value={value?.occupationName}
                  onSelectItem={onChange}
                  {...getErrorProps(intl, errors.occupation)}
                />
              )}
            />
            {occupation && occupation.occupationName === 'Other' && (
              <Controller
                control={control}
                name="occupationName"
                render={() => (
                  <InputWithControl
                    control={control as unknown as Control<any>}
                    name="occupationName"
                    placeholder={t('profile.occupationName')}
                    containerStyle={style.inputContainer}
                  />
                )}
              />
            )} */}

            <InputWithControl
              control={control as unknown as Control<FieldValues>}
              name="mobileNumber"
              label={t('familyMember.mobileNumber')}
              containerStyle={style.inputContainer}
              keyboardType="phone-pad"
              returnKeyType="done"
              maxLength={10}
              {...getErrorProps(intl, errors.mobileNumber)}
              onSubmitEditing={handleSubmit(data =>
                onSubmit({data, onSuccess}),
              )}
            />
            <Controller
              control={control}
              name="bloodGroup"
              render={({field: {onChange, value}}) => (
                <DropDown
                  data={bloodGroups}
                  displayKey="name"
                  //@ts-ignore
                  value={value}
                  onSelectItem={item => {
                    onChange(item.name);
                  }}
                  label={t('profile.bloodGroup')}
                  containerStyle={style.inputContainer}
                />
              )}
            />
            <View style={style.nameRowView}>
              <Controller
                control={control}
                name="gender"
                render={({field: {onChange, value}}) => (
                  <DropDown
                    data={gender}
                    displayKey="name"
                    label={t('familyMember.gender')}
                    containerStyle={style.dropDownContainer}
                    value={value?.name}
                    onSelectItem={onChange}
                    {...getErrorProps(intl, errors.gender?.name)}
                  />
                )}
              />
              <Controller
                control={control}
                name="maritalStatus"
                render={({field: {onChange, value}}) => (
                  <DropDown
                    data={maritalStatus}
                    displayKey="name"
                    label={t('familyMember.maritalStatus')}
                    containerStyle={style.dropDownContainer}
                    value={value?.name}
                    onSelectItem={onChange}
                    {...getErrorProps(intl, errors.maritalStatus?.name)}
                  />
                )}
              />
            </View>
            <Button
              disabled={isLoading}
              style={style.buttonStyle}
              name={<FormattedMessage id={t('misc.submit')} />}
              onPress={handleSubmit(data => onSubmit({data, onSuccess}))}
            />
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </View>
  );
};

export default AddFamilyMember;
