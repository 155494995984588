import {StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    flex: 1,
    display: 'flex',
    paddingVertical: 50,
    paddingHorizontal: 20,
    gap: 30,
  },
  title: {
    fontSize: FontSize.h1,
    color: Colors.blackA100,
    fontFamily: FontFamily.PoppinsBold,
    textAlign: 'center',
  },
  message: {
    fontSize: FontSize.h4,
    color: Colors.blackA100,
    fontFamily: FontFamily.PoppinsMedium,
    textAlign: 'center',
  },
  boxView: {
    flex: 1,
    display: 'flex',
    gap: 20,
  },
  // logoText: {
  //   fontFamily: FontFamily.PoppinsItalic,
  //   fontSize: 20,
  //   // color: '#1e3465',
  //   color: '#17179b',
  //   fontWeight: '600',
  // },
  // logoTextContainer: {
  //   width: '60%',
  //   height: 80,
  //   fontFamily: FontFamily.PoppinsBold,
  //   fontSize: 25,
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   flexWrap: 'wrap',
  //   position: 'relative',
  // },
  rowView: {
    flexDirection: 'row',
    flex: 1,
    display: 'flex',
    gap: 50,
  },
  inquiryForm: {
    fontSize: FontSize.h1,
    color: Colors.primary,
    fontFamily: FontFamily.PoppinsBold,
    textAlign: 'center',
  },
  inquiryFormMessage: {
    fontSize: FontSize.h6,
    color: Colors.lightBlack,
    fontFamily: FontFamily.PoppinsRegular,
    marginBottom: 10,
    textAlign: 'center',
  },
  rowInputView: {
    flexDirection: 'row',
    gap: 15,
  },
  // logoContainer: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   position: 'relative',
  //   alignSelf: 'center',
  //   width: '40%',
  //   justifyContent: 'space-evenly',
  //   alignItems: 'center',
  // },
  // logoStyle: {
  //   alignSelf: 'center',
  // },
  headerTitle: {
    fontSize: FontSize.h2,
    fontFamily: FontFamily.PoppinsSemiBold,
    marginBottom: 5,
    marginTop: 15,
    color: Colors.blackA100,
  },
  headerRowView: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.white,
    justifyContent: 'center',
    paddingVertical: 20,
  },
  backButton: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
    position: 'absolute',
    left: 0,
  },
  backText: {
    fontSize: FontSize.h5,
    fontFamily: FontFamily.PoppinsSemiBold,
    color: Colors.primary,
  },
  copyRights: {
    fontSize: FontSize.body,
    color: Colors.lightBlack,
    fontFamily: FontFamily.PoppinsRegular,
    textAlign: 'center',
  },
  logoText: {
    fontFamily: FontFamily.PoppinsItalic,
    color: '#17179b',
    fontWeight: '600',
  },
  logoTextContainer: {
    width: '70%', // Adjust as needed
    height: 80,
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'relative',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    width: '40%', // Adjust as needed
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  logoStyle: {
    alignSelf: 'center',
  },
});
